import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import { Customer, defaultCustomer } from 'model/dealer/customer.model';
import axios from 'axios';
import { CrudPutAction } from 'reducers/redux-action.type';
import qs from "qs";
const entity = 'customer';
const apiUrl = '/api/yumei-mall-customer/customer'
const tApiUrl = '/api/yumei-mall-customer/customer/list'
const standardReducerImpl = new StandardReducerImpl<Customer>(entity, defaultCustomer, tApiUrl);

export type DealerCustomerState = StandardState<Customer>;
export const dealerCustomer = standardReducerImpl.buildStandardReducer();
export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const signStatusObj: any = {
  NotSigned: "未签署",
  Signing: "签署中",
  Rejected: "拒绝",
  Complete: "已完成",
  Expired: "过期",
  Invaliding: "作废中",
  Invalided: "已作废",
  Failed: "签署失败"
}

// 经销商导出
export const dealerExport = async (params: any) => {
  const requestUrl = `${apiUrl}/export`;
  const res = await axios.get(
    requestUrl + "?" + qs.stringify(params, { indices: false }),
    {
      responseType: "blob",
    }
  );
  return res;
};

// 获取签约合同列表
export const signContractTable = async (params?: any) => {
  const data = await axios.get<any>('/api/yumei-mall-customer/tag/group', { params:{...params} });
  return data.data;
}

// 注销
export const customerLogOff = async (id?: any) => {
  const data = await axios.get<any>(`/api/yumei-mall-customer/customer/log-out/${id}`);
  return data.data;
}


// 解密
export const safeCode = async (data?: any) => {
  const res = await axios.post<any>('/api/yumei-mall-common/safe', data);
  return res.data;
}


// 获取额度
export const exchangeAmount = async (params?: any) => {
  const data = await axios.get<any>(`/api/yumei-mall-purchase/exchange-order/exchange-amount/${params.account}?storeId=${params.storeId}`);
  return data.data;
}

// 添加标签
export const addIdentityTags = async (params?: any) => {
  const data = await axios.get<any>('/api/yumei-mall-customer/tag/group', { params:{...params} });
  return data.data;
}

// 获取标签列表
export const getTagList = async (params?: any) => {
  const data = await axios.get<any>('/api/yumei-mall-customer/customer/relation/getTagList', { params:{...params} });
  return data.data;
}

// 修改标签
export const updateIdentityTags = async (params: any) => {
  const requestUrl = `/api/yumei-mall-customer/customer/update-tags`;
  return await axios.get<any>(requestUrl, { params:{...params} });
}

// 获取区域负责人列表
export const getRegionalHeadList = async () => {
  const data = await axios.get<any>('/api/yumei-mall-region/regionalHeadPeopleResource');
  return data.data;
}


// 获取海外信息
export const fetchOverseasInfo = async (params:any) => {
  const data = await axios.get<any>(`/api/yumei-mall-customer/customer/overseas/${params.id}`,{params:{...params}});
  return data.data;
}

// 批量新增区域负责人
export const addRegionalHeadPeople = async (parrmData:any) => {
  const data = await axios.post<any>('/api/yumei-mall-customer/customer/addRegionalHeadPeople', parrmData);
  return data.data;
}




export const searchCustomerList = async (params: any) => {
  const requestUrl = `/api/yumei-mall-customer/tst-customer`;
  return await axios.get<any>(requestUrl, { params });
}

// 获取合同
export const contractList = async (entity: any) => {
  const requestUrl = `/api/yumei-mall-customer/contract/user-contract?customerId=${entity.customerId}`;
  const axiosResponse = await axios.get<any>(requestUrl);
  return axiosResponse;
}

// 获取客户资金列表
export const fundingList = async (entity?: any) => {
  const requestUrl = `/api/yumei-mall-store/seller/bank-roll/summary-by-account/${entity.userId}`;
  const axiosResponse = await axios.get<any>(requestUrl);
  return axiosResponse.data
}

// 获取授权证书列表
export const getAuthCertificate = async (params?: any) => {
  return await axios.get<any>(`/api/yumei-mall-customer/auth-record/user-auth-record`, { params: { ...params } });
}

export const fetchCustomerList = async (params?: any) => {
  return await axios.get<any>(apiUrl, { params: { ...params } });
}

export const fetchSearchCustomerList = async (params?: any) => {
  const axiosResponse = await axios.get<any>(apiUrl, { params: { ...params } });
  return axiosResponse.data || []
}

export const updateCustomer: CrudPutAction<any> = (entity) => async dispatch => {
  const requestUrl = `/api/yumei-mall-customer/tst-customer/${entity?.id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.put(requestUrl, entity),
  });
};

export const unBind: CrudPutAction<any> = (id: string) => async dispatch => {
  const requestUrl = `${apiUrl}/link/unbind/${id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl),
  });
};