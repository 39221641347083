import React, { FC, Key, ReactElement, useRef, useState } from 'react'
import { searchProduct } from './product.reducer';
import { ColumnsProductStatus, Product } from 'model/product/product.model';
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, Space, Tag, Typography, Image, Form } from 'antd';
import { ModalForm, ProFormText } from "@ant-design/pro-form";
import { PlusOutlined } from "@ant-design/icons";
import { internationalList } from 'components/IntelFormText';
import SelectSearch from 'components/select-search';
import { fetchStoreList } from 'pages/dealer/unit/unit.reducer';
import { fetchSearchBrandList } from '../brand/brand.reducer';
import { FilterCriteria } from 'components/model/share.model';

// export const columns: ProColumns<Product>[] = [{
//   title: '图片',
//   hideInSearch: true,
//   render: (dom, record) => record.mainPicture && <Image width={80} src={internationalList(record.mainPicture || '')} />
// }, {
//   title: '商品信息',
//   dataIndex: 'status',
//   hideInSearch: true,
//   valueEnum: ColumnsProductStatus,
//   render: (dom, record) =>
//     <Space direction="vertical">
//       <Space>
//         商品id：<Typography.Text copyable={true}>{record.id}</Typography.Text>
//         <Tag color={record.status === 'SELLING' ? 'green' : 'red'}>{dom}</Tag>
//       </Space>
//       <Typography.Text>{internationalList(record.name || '')}</Typography.Text>
//       <Space>
//         SPU编码：<Typography.Text copyable={true}>{record.code}</Typography.Text>
//       </Space>
//     </Space>
// }, {
//   title: '商家',
//   width: 100,
//   dataIndex: 'sellerName',
//   hideInSearch: true,
// }, {
//   title: '商品名称',
//   dataIndex: 'name.contains',
//   hideInTable: true,
//   renderFormItem: () => {
//     return <Form.Item name={'name.contains'}><ProFormText
//       width="md"
//       name="name.contains"
//       label=""
//     /></Form.Item>
//   }
// }, {
//   title: '商品id',
//   dataIndex: 'id.equals',
//   hideInTable: true,
//   renderFormItem: () => {
//     return <Form.Item name={'id.equals'}><ProFormText
//       width="md"
//       name="id.equals"
//       label=""
//     /></Form.Item>
//   }
// }, {
//   title: 'SPU编码',
//   dataIndex: 'code.equals',
//   hideInTable: true,
//   renderFormItem: () => {
//     return <Form.Item name={'code.equals'}><ProFormText
//       width="md"
//       name="code.equals"
//       label=""
//     /></Form.Item>
//   }
// }, {
//   title: '商家',
//   dataIndex: 'sellerId.equals',
//   hideInTable: true,
//   width: 100,
//   renderFormItem: (dom, record) => {
//     return <div className='TableSearchSelect'>
//       <Form.Item name={'sellerId.equals'}>
//         <SelectSearch width={'md'} required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
//       </Form.Item>
//     </div>
//   }
// }, {
//   title: '品牌',
//   dataIndex: 'brandId.equals',
//   hideInTable: true,
//   width: 100,
//   renderFormItem: (dom, record) => {
//     return <div className='TableSearchSelect'>
//       <Form.Item name={'brandId.equals'}>
//         <SelectSearch width='md' required={false} name="brandId.equals" placeholder="品牌" optionValue='id' labelValue="brandName" defaultParams='id.equals' valueType="number" fetchFunc={fetchSearchBrandList} searchParams='name.contains' />
//       </Form.Item>
//     </div>
//   }
// }, {
//   title: '商品类目',
//   dataIndex: ['category', 'name'],
//   width: 100,
//   hideInSearch: true,
//   render: (text, record) => internationalList(record?.category?.name || '')
// }, {
//   title: '品牌',
//   dataIndex: ['brand', 'brandName'],
//   hideInSearch: true,
//   width: 100,
//   render: (text, record) => internationalList(record?.brand?.brandName || '')
// }];

export interface ProductSelectionFormProps {
  trigger?: string;
  triggerRender?: ReactElement;
  name?: string;
  multiple?: boolean;
  hideSearch?: boolean;
  editSku?: boolean;
  productIds?: string[];
  requestParams?: any;
  fetchFunc?: (params: any) => any;
  onFinish: (productIds: string[], products: Product[]) => void;
  level?: 'spu' | 'sku',
  skuType?: 'OrderingOnBehalfOfCustomers' | 'general',//代客下单、通用
  scrollConfig?: any;
  productSearch?: {
    hiddenSellerId?: boolean;
    hiddenBrandId?: boolean;
  }
  fetchNumList?: any;
}

const ProductSelectionForm: FC<ProductSelectionFormProps> = (props) => {
  const {
    // getEntities,
    fetchNumList,
    trigger,
    onFinish,
    triggerRender,
    multiple = true,
    scrollConfig = {},
    fetchFunc,
    level = 'spu',
    skuType = 'general',
    hideSearch = false,
    editSku = false,
    requestParams = {},
    productSearch
  } = props;
  const numList = useRef<{ quantity: number, skuId: any }[]>([]);

  const editSkuColumns: ProColumns<any>[] = [{
    title: 'SKU编码',
    width: 100,
    dataIndex: ['code'],
    hideInSearch: true,
  }, {
    title: '规格',
    width: 100,
    dataIndex: ['specs'],
    hideInSearch: true,
    render: (text, record: any) => record.specs && record.specs.map((tag: any) => <span>{tag.v}，</span>),
  }]

  const skuColumns: ProColumns<any>[] = skuType === 'general' ? [{
    title: '图片',
    hideInSearch: true,
    width: 100,
    render: (dom: any, record: any) => record?.product?.mainPicture && <Image width={80} src={record?.product?.mainPicture} />
  }, {
    title: '商品名称',
    width: 100,
    dataIndex: ['product', 'name'],
    hideInSearch: true,
  }, {
    title: '状态',
    width: 100,
    dataIndex: ['product', 'status'],
    valueEnum: ColumnsProductStatus,
    hideInSearch: true,
  }, {
    title: 'SPU编码',
    width: 100,
    dataIndex: ['product', 'code'],
    hideInSearch: true,
  }, {
    title: 'SKU编码',
    width: 100,
    dataIndex: ['code'],
    hideInSearch: true,
  }, {
    title: '规格',
    width: 100,
    dataIndex: ['specs'],
    hideInSearch: true,
    render: (text, record: any) => record.specs && record.specs.map((tag: any) => <span>{tag.v}，</span>),
  },{
    title: '商家',
    width: 100,
    dataIndex: ['product', 'sellerName'],
    hideInSearch: true,
  }, {
    title: '品牌',
    width: 100,
    dataIndex: ['product', 'brand', 'brandName'],
    hideInSearch: true,
  }, {
    title: '商品名称',
    dataIndex: 'name.contains',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'name.contains'}><ProFormText
        width="md"
        name="name.contains"
        label=""
      /></Form.Item>
    }
  }, {
    title: 'SPU编码',
    dataIndex: 'code.equals',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'code.equals'}><ProFormText
        width="md"
        name="code.equals"
        label=""
      /></Form.Item>
    }
  }, {
    title: 'SKU编码',
    dataIndex: 'skuCode.equals',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'skuCode.equals'}><ProFormText
        width="md"
        name="skuCode.equals"
        label=""
      /></Form.Item>
    }
  }, {
    title: '商家',
    dataIndex: 'sellerId.equals',
    hideInTable: true,
    width: 100,
    hideInSearch: !!requestParams?.['sellerId.equals'] || productSearch?.hiddenSellerId,
    renderFormItem: (dom, record) => {
      return <div className='TableSearchSelect'>
        <Form.Item name={'sellerId.equals'}>
          <SelectSearch width={'md'} required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        </Form.Item>
      </div>
    }
  }, {
    title: '品牌',
    dataIndex: 'brandId.equals',
    hideInSearch: productSearch?.hiddenBrandId || false,
    hideInTable: true,
    width: 100,
    renderFormItem: (dom, record) => {
      return <div className='TableSearchSelect'>
        <Form.Item name={'brandId.equals'}>
          <SelectSearch width='md' required={false} name="brandId.equals" placeholder="品牌" optionValue='id' labelValue="brandName" defaultParams='id.equals' valueType="number" fetchFunc={fetchSearchBrandList} searchParams='name.contains' />
        </Form.Item>
      </div>
    }
  }] : [{
    title: '图片',
    hideInSearch: true,
    width: 100,
    render: (dom: any, record: any) => record?.product?.mainPicture && <Image width={80} src={record?.product?.mainPicture} />
  }, {
    title: '商品名称',
    width: 100,
    dataIndex: ['product', 'name'],
    hideInSearch: true,
  }, {
    title: '状态',
    width: 100,
    dataIndex: ['product', 'status'],
    valueEnum: ColumnsProductStatus,
    hideInSearch: true,
  }, {
    title: '库存',
    width: 100,
    dataIndex: ['quantity'],
    hideInSearch: true,
  }, {
    title: 'SPU编码',
    width: 100,
    dataIndex: ['product', 'code'],
    hideInSearch: true,
  }, {
    title: 'SKU编码',
    width: 100,
    dataIndex: ['sku', 'code'],
    hideInSearch: true,
  }, {
    title: '规格',
    width: 100,
    dataIndex: ['sku', 'specs'],
    hideInSearch: true,
    render: (text, record: any) => record?.sku?.specs && record?.sku?.specs.map((tag: any) => <span>{tag.k}：{tag.v}；</span>),
  }, {
    title: '采购数量',
    width: 100,
    dataIndex: 'id',
    hideInSearch: true,
    render: (dom, entity, index, action, schema) => {
      return <ProFormText fieldProps={{
        onChange: (e) => {
          const quantity = Number(e.target.value) || 0;
          const skuId = entity.id || '';
          if (numList.current.length === 0) {
            numList.current = (numList.current.concat({ quantity, skuId }))
          } else {
            const length = numList.current.filter(e => e.skuId === skuId).length;
            (length > 0) ? (numList.current = numList.current.map(e => {
              return {
                ...e,
                quantity: skuId === e.skuId ? quantity : e.quantity
              }
            })) : (numList.current = (numList.current.concat({ quantity, skuId })))
          }

        }
      }} />
    },
  }, {
    title: '商品名称',
    dataIndex: 'name.contains',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'name.contains'}><ProFormText
        width="md"
        name="name.contains"
        label=""
      /></Form.Item>
    }
  }, {
    title: 'SPU编码',
    dataIndex: 'code.equals',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'code.equals'}><ProFormText
        width="md"
        name="code.equals"
        label=""
      /></Form.Item>
    }
  }, {
    title: 'SKU编码',
    dataIndex: 'skuCode.equals',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'skuCode.equals'}><ProFormText
        width="md"
        name="skuCode.equals"
        label=""
      /></Form.Item>
    }
  }, {
    title: '商家',
    dataIndex: 'sellerId.equals',
    hideInTable: true,
    width: 100,
    hideInSearch: !!requestParams?.['sellerId.equals'],
    renderFormItem: (dom, record) => {
      return <div className='TableSearchSelect'>
        <Form.Item name={'sellerId.equals'}>
          <SelectSearch width={'md'} required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        </Form.Item>
      </div>
    }
  }, {
    title: '品牌',
    dataIndex: 'brandId.equals',
    hideInTable: true,
    width: 100,
    renderFormItem: (dom, record) => {
      return <div className='TableSearchSelect'>
        <Form.Item name={'brandId.equals'}>
          <SelectSearch width='md' required={false} name="brandId.equals" placeholder="品牌" optionValue='id' labelValue="brandName" defaultParams='id.equals' valueType="number" fetchFunc={fetchSearchBrandList} searchParams='name.contains' />
        </Form.Item>
      </div>
    }
  }];

  const columns: ProColumns<Product>[] = [{
    title: '图片',
    hideInSearch: true,
    render: (dom, record) => record.mainPicture && <Image width={80} src={internationalList(record.mainPicture || '')} />
  }, {
    title: '商品信息',
    dataIndex: 'status',
    hideInSearch: true,
    valueEnum: ColumnsProductStatus,
    render: (dom, record) =>
      <Space direction="vertical">
        <Space>
          商品id：<Typography.Text copyable={true}>{record.id}</Typography.Text>
          <Tag color={record.status === 'SELLING' ? 'green' : 'red'}>{dom}</Tag>
        </Space>
        <Typography.Text>{internationalList(record.name || '')}</Typography.Text>
        <Space>
          SPU编码：<Typography.Text copyable={true}>{record.code}</Typography.Text>
        </Space>
      </Space>
  }, {
    title: '商家',
    width: 100,
    dataIndex: 'sellerName',
    hideInSearch: true,
  }, {
    title: '商品名称',
    dataIndex: 'name.contains',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'name.contains'}><ProFormText
        width="md"
        name="name.contains"
        label=""
      /></Form.Item>
    }
  }, {
    title: '商品id',
    dataIndex: 'id.equals',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'id.equals'}><ProFormText
        width="md"
        name="id.equals"
        label=""
      /></Form.Item>
    }
  }, {
    title: 'SPU编码',
    dataIndex: 'code.equals',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'code.equals'}><ProFormText
        width="md"
        name="code.equals"
        label=""
      /></Form.Item>
    }
  }, {
    title: '商家',
    dataIndex: 'sellerId.equals',
    hideInTable: true,
    hideInSearch: !!requestParams?.['sellerId.equals'] || productSearch?.hiddenSellerId,
    width: 100,
    renderFormItem: (dom, record) => {
      return <div className='TableSearchSelect'>
        <Form.Item name={'sellerId.equals'}>
          <SelectSearch width={'md'} required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        </Form.Item>
      </div>
    }
  }, {
    title: '品牌',
    dataIndex: 'brandId.equals',
    hideInTable: true,
    hideInSearch: productSearch?.hiddenBrandId || false,
    width: 100,
    renderFormItem: (dom, record) => {
      return <div className='TableSearchSelect'>
        <Form.Item name={'brandId.equals'}>
          <SelectSearch width='md' required={false} name="brandId.equals" placeholder="品牌" optionValue='id' labelValue="brandName" defaultParams='id.equals' valueType="number" fetchFunc={fetchSearchBrandList} searchParams='name.contains' />
        </Form.Item>
      </div>
    }
  }, {
    title: '商品类目',
    dataIndex: ['category', 'name'],
    width: 100,
    hideInSearch: true,
    render: (text, record) => internationalList(record?.category?.name || '')
  }, {
    title: '品牌',
    dataIndex: ['brand', 'brandName'],
    hideInSearch: true,
    width: 100,
    render: (text, record) => internationalList(record?.brand?.brandName || '')
  }];

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<Product[]>([]);
  const [allData, setAllData] = useState<any>([]);
  const [page, setPage] = useState<number>(1);

  return (
    <ModalForm<Product[]>
      title="选择商品"
      width={1200}
      trigger={triggerRender ||
        <Button type="primary">
          <PlusOutlined />
          {trigger}
        </Button>
      }
      onVisibleChange={(e) => {
        e && (numList.current = []);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setAllData([]);
        setPage(1);
      }}
      modalProps={{
        destroyOnClose: true,
      }}
      onFinish={async () => {
        const ids = (selectedRowKeys?.map(item => item.toString())) || [];
        numList.current = numList.current.filter(e => ids.indexOf(e.skuId) > -1);
        fetchNumList && fetchNumList(numList.current)
        return onFinish(ids, selectedRows)
      }}
    >
      <ProTable<Product, Record<string, any>>
        className="selectPimTable"
        rowKey="id"
        search={hideSearch ? false : {
          // filterType: 'light',
          // optionRender: false,
          // collapsed: false,
          span: 4,
          labelWidth: 'auto',
        }}
        scroll={scrollConfig}
        dateFormatter="string"
        columns={level === 'spu' ? columns : (editSku ? editSkuColumns : skuColumns)}
        // dataSource={entities}
        // loading={loading}
        rowSelection={{
          type: multiple ? 'checkbox' : 'radio',
          selectedRowKeys,
          onChange: (selectedRowKeysKey: Key[], selectedRows) => {
            setSelectedRowKeys(selectedRowKeysKey);
            setSelectedRows(selectedRows);
            const pageKey = 'page' + page;
            const newAllData = {
              ...allData,
              [pageKey]: {
                page,
                data: allData[pageKey].data.map((s: any) => {
                  return {
                    ...s,
                    selected: selectedRowKeysKey.indexOf(s.id) > -1 ? true : false
                  }
                })
              }
            };
            const objArr = Object.keys(newAllData);
            let num = [];
            const newSelectedRowKeys: any = [];
            const newSelectedRows: any = [];
            const funC = () => {
              newAllData[objArr[num.length]]?.data.forEach((element: any, index: number) => {
                if (!!element.selected) {
                  newSelectedRowKeys.push(element.id);
                  newSelectedRows.push(element);
                }
                if (index + 1 === newAllData[objArr[num.length]]?.data.length) {
                  num.push(1);
                  if (num.length < objArr.length) {
                    funC();
                  } else {

                    setAllData(newAllData);
                  }
                };
              });
            }; funC();
          },
        }}
        pagination={{
          showQuickJumper: true,
        }}
        // toolBarRender={false}
        request={async (params) => {
          const filterObj = {
            ...params,
            ...requestParams,
          };
          const newFilterObj: FilterCriteria = {};
          const objArr = Object.keys(filterObj);
          await objArr.forEach((key, index) => {
            const val = filterObj[key];
            if (val !== '' && key !== 'searchText') {
              newFilterObj[key] = val;
            };
            // if (index === objArr.length - 1) {
            // }
          });
          const result = !!fetchFunc ? await fetchFunc({
            page: params?.current || 0,
            size: params?.pageSize || 20,
            ...newFilterObj,
          }) : await searchProduct({
            page: params?.current || 0,
            size: params?.pageSize || 20,
            ...newFilterObj,
          });
          const page = newFilterObj.current;
          setPage(page);
          if (allData.length === 0) {
            setAllData({
              ['page' + page]: {
                page,
                data: result.data.map((e: any) => {
                  return {
                    ...e,
                    selected: false,
                  }
                }),
              }
            });
          } else {
            if (allData['page' + page]) {
              setAllData({
                ...allData,
                ['page' + page]: {
                  page,
                  data: result.data.map((e: any) => {
                    return {
                      ...e,
                      selected: allData['page' + page].data.filter((s: any) => s.id === e.id)?.[0]?.selected || false,
                    }
                  }),
                }
              });
            } else {
              setAllData({
                ...allData,
                ['page' + page]: {
                  page,
                  data: result.data.map((e: any) => {
                    return {
                      ...e,
                      selected: false,
                    }
                  }),
                }
              });
            }
          }

          return {
            success: true,
            total: result.total,
            data: result.data,
          };
        }}
      />
    </ModalForm>
  );
};

export default ProductSelectionForm;