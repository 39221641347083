import { ModalForm, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form"
import { Button, Col, Form, Input, message, Row, Select } from "antd";
import { ProvinceCode } from "model/dealer/unit.model";
import { queryBladeSystemSelect } from "pages/dealer/unit/unit.reducer";
import { useEffect, useState } from "react";
import { Storage } from 'utils/storage-util';
import { addAddress } from "./order.reducer";
const { Option } = Select;

interface AddAddressProps {
    customerId: string;
    userId: string;
}
const AddAddress = (props: AddAddressProps) => {
    const { customerId, userId } = props;
    const [provinceSelectData, setProvinceSelectData] = useState<ProvinceCode[]>([]);//省
    const [citySelectData, setCitySelectData] = useState<ProvinceCode[]>([]);//市
    const [districtSelectData, setDistrictSelectData] = useState<ProvinceCode[]>([]);//区
    const [form] = Form.useForm();

    useEffect(() => {
        setDataFun();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);


    const setDataFun = async () => {
        const xTenant = Storage.session.get("x-tenant");
        const regionObj: any = {
            tmm: ['00', '11', '1101'],
            ttw: ['01', '0101', '010101'],//台湾
            tml: ['N3', 'N301', 'N30101'],//马来西亚
            tsg: ['02', '0201', '020101'],//新加坡
        };
        const regionKey: 'tmm' | 'ttw' | 'tml' | 'tsg' = regionObj[xTenant] ? xTenant : 'tmm';
        setProvinceSelectData(await getBladeSystemSelect(regionObj[regionKey][0]));
        setCitySelectData(await getBladeSystemSelect(regionObj[regionKey][1]));
        setDistrictSelectData(await getBladeSystemSelect(regionObj[regionKey][2]));
    };

    //联系地址
    const getBladeSystemSelect = async (code: string | number) => {
        const res = await queryBladeSystemSelect(code);
        return (res && res.length > 0 && res) || [];
    }

    const handleProvinceChange = async (value: any, option: any) => {
        form.setFieldsValue({ city: '', district: '' });
        setCitySelectData(await getBladeSystemSelect(value));
        setDistrictSelectData([]);
    };

    const handleCityChange = async (value: any, option: any) => {
        form.setFieldsValue({ district: '' });
        setDistrictSelectData(await getBladeSystemSelect(value));
    };

    return <ModalForm
        width={1700}
        title={'代客下单'}
        form={form}
        trigger={<Button style={{ marginTop: '28px' }} type='primary' >新增地址</Button>}
        modalProps={{ destroyOnClose: true, }}
        onFinish={async (values) => {
            return new Promise<boolean>((resolve, reject) => {
                const provinceName = provinceSelectData.filter(e => e.code === values?.province)[0].name || '';
                const cityName = citySelectData.filter(e => e.code === values?.city)[0].name || '';
                const districtName = districtSelectData.filter(e => e.code === values?.district)[0].name || '';
                const obj = {
                    "customerId": customerId,
                    "userId": userId,
                    districtName,
                    provinceName,
                    cityName,
                    ...values,
                }
                addAddress(obj).then(e => {
                    resolve(true);
                    message.success('地址新增成功')
                }).catch(e => {
                    resolve(false);
                    message.error(e.response.data.title);
                });
            });
        }}
    >
        <Row>
            <Col span="7">
                <ProFormText
                    name="mobile"
                    label="手机号"
                    rules={[
                        { required: true, message: '请输入手机号！' },
                        {
                            pattern: /^1[3-9]\d{9}$/,
                            message: '请输入有效的手机号！',
                        },
                    ]}
                    width="sm"
                />
            </Col>
            <Col span="1"></Col>
            <Col span="7">
                <ProFormText
                    name="zipcode"
                    label="邮政编码"
                    width="sm"
                />
            </Col>
            <Col span="8" />
            <Col span="7">
                <ProFormText
                    name="consigneeName"
                    label="收件人"
                    rules={[{ required: true }]}
                    width="sm"
                />
            </Col>
            <Col span="1"></Col>
            <Col span="12">
                <Form.Item label='收件地址选择'>
                    <Input.Group compact>
                        <Form.Item name={['province']} noStyle rules={[{ required: true }]}>
                            <Select style={{ width: 100 }} onChange={handleProvinceChange} placeholder="省"
                                showSearch
                                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                            >
                                {provinceSelectData && provinceSelectData.map((province: ProvinceCode) =>
                                    <Option key={province.code} value={String(province.code)}>{province.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item name={['city']} noStyle rules={[{ required: true }]}>
                            <Select style={{ width: 100 }} onChange={handleCityChange} placeholder="市"
                                showSearch
                                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                            >
                                {citySelectData && citySelectData.map((city: ProvinceCode) =>
                                    <Option key={city.code} value={String(city.code)}>{city.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item name={['district']} noStyle rules={[{ required: true }]}>
                            <Select style={{ width: 100 }} placeholder="区"
                                showSearch
                                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                            >
                                {districtSelectData && districtSelectData.map((district: ProvinceCode) =>
                                    <Option key={district.code} value={String(district.code)}>{district.name}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
            </Col>
            <Col span="4">
                <ProFormSwitch name="isDefault"
                    label="是否默认"
                    width='sm'
                    initialValue={0}
                    getValueFromEvent={value => (value && 1) || 0}
                    getValueProps={value => ({ checked: value === 1 ? true : false })}
                    fieldProps={{
                        checkedChildren: '是',
                        unCheckedChildren: '否'
                    }}
                />
            </Col>
            <Col span="15">
                <ProFormTextArea
                    name="address"
                    label="详细地址"
                    rules={[{ required: true }]}
                />
            </Col>
        </Row>
    </ModalForm>
}

export default AddAddress