import { FC, ReactNode, useEffect, useState } from "react";
import { Button, Form, Input, Tooltip, message, Popconfirm, Image } from 'antd';
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity, setBigCustomer } from "./fund.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { Fund } from "model/financial/fund.model";
import { fundAdjust } from "./fund.reducer";
import { ModalForm, ProFormDependency, ProFormDigit, ProFormRadio, ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import FlowDetails from './flowDetails'
import { internationalList } from "components/IntelFormText";
import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { fetchBrandList } from "pages/pim/brand/brand.reducer";
import { fetchStoreList, rechargeTypeList } from "pages/dealer/unit/unit.reducer";
import { ForceUpdate } from "model/system/version.model";
import wallt from '../../../assets/images/home/pay.png'
import AddNewAccount from './addNewAccount'
import { Storage } from 'utils/storage-util';

export interface FundListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};




const FundList: FC<FundListProps> = (props) => {
  const { fundAdjust, entities } = props;
  let [key, setKey] = useState(0)
  let [tableKey, setTableKey] = useState(0)
  const [BrandList, setBrandList] = useState<any>([]);
  const [ZJVisible, setZJVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [record, setRecord]: any = useState({});
  const [form] = Form.useForm();

  const columns: ProColumns<any>[] = [{
    title: '账户类型',
    dataIndex: 'accountTypeName',
    render: (dom, record) => record.accountTypeName === '保证金' ? (record.accountTypeName + '(' + record.cashDepositName + ')') : record.accountTypeName
  },
  {
    title: '货款类型',
    dataIndex: 'fromTypeName',
    render: (text: any, record: any) => { return internationalList(record.fromTypeName) + '-' + internationalList(record.toTypeName || '') },
  },
  {
    title: '是否大商款',
    dataIndex: 'bigCustomer',
    valueEnum: ForceUpdate,
  },
  {
    title: '金额',
    dataIndex: 'amount',
    render: (text: any, record: any) => {
      let tip: any = ''
      for (let key in record.brandDetailMap) {
        if (record.brandDetailMap.hasOwnProperty(key)) {
          let keys = key.split(',')
          let names: any = []
          keys.forEach((item => {
            BrandList.forEach((bitem: any) => {
              if (item === bitem.value) {
                names.push(bitem.label)
              };
            })
          }))
          tip += names.join(',') + ':' + record.brandDetailMap[key] + '<br/>'
        }
      }
      return <div style={{ cursor: 'pointer' }}> {text}  {tip && <Tooltip
        title={<div dangerouslySetInnerHTML={{ __html: tip }} />}
        trigger="hover"
      > <QuestionCircleOutlined style={{ color: "#ff4d4f" }} />
      </Tooltip>}
      </div>
    }
  },
  {
    title: '可用金额',
    dataIndex: 'availableAmount',
  },
  {
    title: '冻结金额',
    dataIndex: 'frozenAmount'
  },
  {
    title: '资金类型',
    dataIndex: 'onlineBalance',
    render: (dom, record) => {
      return !!record.onlineBalance ? '线上资金(不可随意调整)' : '线下资金'
    }
  },
  {
    title: '赠送金额',
    dataIndex: 'giftAmount',
  }, {
    title: '打款对象',
    dataIndex: 'fromName',
  }, {
    title: '收款对象',
    dataIndex: 'toStoreName',
  }, {
    title: '付款人授权码',
    dataIndex: 'payerCode',
  }, {
    title: '收款人授权码',
    dataIndex: 'payeeCode',
  }];


  useEffect(() => {
    getBrandList();
    setKey(++key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 获取品牌列表
  const getBrandList = () => {
    fetchBrandList().then(data => {
      let list = data.map((item: any) => {
        return {
          label: item.brandName,
          value: item.id
        }
      })
      setBrandList(list)
    })
  }

  // 资金调整
  const fundAdjustButtonRender: any = (dom: ReactNode, record: Fund): ReactNode => (
    <Tooltip title="资金调整">
      <Button
        disabled={record.accountTypeName === '保证金' && record.cashDepositName === '中康共享'}
        size="small"
        type='link'
        onClick={() => {
          const locationTime = Storage.session.get('fundAdjustment');
          setRecord(record);
          if (Date.now() < locationTime) {
            message.success('验证成功')
            setZJVisible(true);
          } else {
            setVisible(true);
          }
        }}
      >调整</Button>
    </Tooltip>
  );

  // 打开资金明细弹框
  const showModal = async (record: any) => {
    setRecord({ ...record })
    setOpen(true);
    setKey(++key)
  };

  // 关闭资金明细弹框
  const hideModal = () => {
    setOpen(false);
    setOpenAccount(false)
    setRecord({})
    setTableKey(Math.random())
  };

  // 资金详情
  const fundDetailButtonRender: any = (dom: ReactNode, record: any): ReactNode => {
    return (
      <Tooltip title="资金详情">
        <Button size="small" type='link' onClick={() => showModal(record)}>详情</Button>
      </Tooltip>
    )
  };

  // 新增账户
  const AddNewAccountButtonRender = [
    <Tooltip title="新增账户">
      <Button size="small" type='primary' onClick={() => {
        setOpenAccount(true)
      }}>新增账户</Button>
    </Tooltip>
  ]

  // 设置大商钱包
  const setlargeWalletButtonRender: any = (dom: ReactNode, record: any): ReactNode => {
    return (<>
      {record.accountType === 'Goods' && record.bigCustomer === false && record.paymentType === 'L40-L60' &&
        <Tooltip title="设置大商钱包" key="delete">
          <Popconfirm
            title={"你确定要设置大商钱包吗？"}
            icon={<WarningOutlined />}
            onConfirm={() => {
              setBigCustomer({
                id: record.id
              }).then((e: any) => {
                setTableKey(Math.random())
              }).catch((e) => {
                message.error(e.response.data.message)
              })
            }}
          >
            <Image className="wallt-img" src={wallt} width={20} preview={false}></Image>
          </Popconfirm>
        </Tooltip>
      }</>
    )
  };



  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          request={async (params) => {
            const data: any = await fetchStoreList();
            return data.map((item: any) => ({
              value: item.id,
              label: item.name
            }))
          }}
          name={['storeId.equals']}
          placeholder="店铺"
        />
        <div className="wd10" />
        <ProFormSelect
          placeholder="账户类型"
          width="md"
          name='bankRollAccountType.equals'
          request={async () => {
            const unitRequest: any = await rechargeTypeList();
            return unitRequest.rechargeType.map((item: any) => ({
              value: item?.name,
              label: item?.desc,
            }))
          }}
        />
        <div className="wd10" />
        <ProFormText
          name="payerCode.equals"
          placeholder="付款人授权码"
        />
        <div className="wd10"></div>
        <ProFormText
          name="payeeCode.equals"
          placeholder="收款人授权码"
        />
      </Input.Group>
    </Form.Item>
  );

  //导出
  const exportData = (data: any[]) => {
    let entities: any = [];
    data.forEach((record) => {
      let arr = {
        ...record,
        onlineBalance: !!record.onlineBalance ? '线上资金(不可随意调整)' : '线下资金',
        fromTypeName: internationalList(record.fromTypeName) + '-' + internationalList(record.toTypeName || '')
      };
      entities.push(arr);
    });
    return entities;
  };

  return (<>
    <ListViewPage<Fund>
      columns={columns}
      actionColumnWidth={140}
      hideDeleteButton
      showDownload
      downloadEntities={exportData(entities)}
      downloadAllEntities={exportData}
      hideEditButton
      key={'id'}
      refreshFlag={tableKey}
      rowActions={[setlargeWalletButtonRender, fundAdjustButtonRender, fundDetailButtonRender]}
      hideCreateButton
      quickFilterPanel={quickFilterPanel}
      detailsIndex={false}
      actions={AddNewAccountButtonRender}
      {...props}
    >
      {props.children}
    </ListViewPage>
    {/* 充值弹框 */}
    <FlowDetails key={key} visible={open} record={record} onCancel={() => {
      hideModal();
    }} onRecharge={(data: any) => {
      hideModal();
    }} />

    {/* 添加账户 */}
    <AddNewAccount visible={openAccount} record={record} onCancel={() => {
      hideModal();
    }} onRecharge={() => {
      hideModal();
      message.success('新增账户成功')
    }} />

    <ModalForm
      title="资金调整密码验证"
      width={600}
      modalProps={{
        destroyOnClose: true,
      }}
      visible={visible}
      onVisibleChange={(e) => !e && setVisible(false)}
      disabled={record.accountTypeName === '保证金' && record.cashDepositName === '中康共享'}
      onFinish={async (data: any) => {
        if (data.password === 'tz310000') {

          let fundAdjustment = Date.now() + (1000 * 60 * 60);
          Storage.session.set('fundAdjustment', fundAdjustment);

          message.success('操作成功')
          setVisible(false);
          setZJVisible(true);
        } else {
          setVisible(true);
          message.error('密码输入错误，请联系管理员！')
        }
      }}
    >
      <ProFormText extra='密码验证完成后将为您免密一小时' name='password' label="资金调整密码" placeholder='资金调整密码' rules={[{ required: true }]} />
    </ModalForm>

    <ModalForm
      title="资金调整"
      visible={ZJVisible}
      onVisibleChange={(e) => !e && setZJVisible(false)}
      modalProps={{
        destroyOnClose: true,
      }}
      width={600}
      {...formItemLayout}
      form={form}
      preserve={false}
      onFinish={async (data: any) => {
        return new Promise<boolean>((resolve, reject) => {
          let total = parseFloat(data.amount || 0) + parseFloat(data.giftAmount || 0)
          if (total <= 0) {
            message.warn('调整必须大于0')
            reject(false)
          } else {
            let resData = {
              ...data,
              bankRollId: record.id,
            }
            message.success('操作成功')
            fundAdjust(resData)
            setTableKey(Math.random())
            resolve(true)
            setZJVisible(false);
          }
        });
      }}
    >
      <ProFormRadio.Group
        label="调整类型"
        extra='注：充值流水调整会调整当前资金钱包金额，并在银行流水中生成充值/扣除的一条流水记录，对财务对账或业绩统计都有影响'
        name={'manualType'}
        rules={[{ required: true }]}
        initialValue={1}
        options={[
          {
            label: '资金调整(不涉及流水操作)',
            value: 1,
          }, {
            label: '充值流水调整(涉及流水操作)',
            value: 2,
          }
        ]}
      />
      <ProFormDigit name='amount' label="调整金额" placeholder='调整金额' min='0' rules={[{ required: true }]} />
      <ProFormDependency name={['manualType']}>
        {({ manualType }) => {
          return manualType === 1 && (record.accountTypeName !== '保证金' && record.accountTypeName !== '培训费') && <ProFormDigit name='giftAmount' label="调整赠送金额" placeholder='调整赠送金额' min='0' />
        }}
      </ProFormDependency>

      <ProFormRadio.Group
        label="操作"
        name={'operation'}
        rules={[{ required: true }]}
        initialValue={1}
        options={[
          {
            label: '加',
            value: 1,
          }, {
            label: '减',
            value: -1,
          }
        ]}
      />
      <ProFormTextArea
        rules={[{ required: true }]}
        name="transactionRemark"
        allowClear={true}
        label="调整备注"
      />
    </ModalForm>
  </>
  );
};

const mapStateToProps = ({ fund }: RootState) => ({
  entity: fund.entity,
  entities: fund.entities,
  loading: fund.loading,
  updateSuccess: fund.updateSuccess,
  createSuccess: fund.createSuccess,
  total: fund.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  fundAdjust
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FundList);