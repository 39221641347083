import React, { FC, ReactNode, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { addParticipation, deleteParticipation, editeRoomNos, editParticipation, fetchRoomsList, fetchTrainListDetailsList, getEntity, meetingDeduction, statisticsTrain } from './trainList.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { TrainList } from "model/train/trainList.model";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, Form, Popconfirm, Tooltip, message, Image } from "antd";
import { ModalForm, ProFormDateTimePicker, ProFormDependency, ProFormDigit, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import ParticipationControl from "./participation";
import { LiveGoods } from "model/product/product.model";
import { useEnumValue } from "common/enum/use-enum-value";
import Title from "antd/lib/typography/Title";
import ExportDataDialog from "export/export-data-dialog";
import { sex } from "model/customerCore/customer.model";
import QRCodeControl from "./QRCode";
import ChangeRoomnumberPop from './changeRoomnumberPop';


export interface TrainListDetailsProps extends DetailsStateAndDispatchProps<TrainList, 'trainType'>,
  RouteComponentProps, StateProps, DispatchProps {
  text?: any;
}
const TrainListDetails: FC<TrainListDetailsProps> = (props) => {

  const { entity, text } = props;
  const [form] = Form.useForm();
  const [refreshTag, setRefreshTag] = useState(1);
  const [randomText, setRandomText] = useState(1);
  const { convertEnumToOptionType } = useEnumValue();


  useEffect(() => {
    entity.id && setRefreshTag(new Date().getTime());
    setTimeout(() => {
      setRefreshTag(new Date().getTime());
    },1000)
  }, [entity.id, text]);

  const roomColumns = [{
    title: '房间号',
    dataIndex: 'roomNo',
  }, {
    title: '人数',
    dataIndex: '',
    hideInSearch: true,
    render: (dom: any, record: any) => record?.roomPersons.length || 0
  }, {
    title: '姓名',
    dataIndex: '',
    hideInSearch: true,
    render: (dom: any, record: any) => record.roomPersons && record.roomPersons.map((e: any, index: number) => e.name + (index === record.roomPersons.length - 1 ? '' : '、'))
  },];

  const detailedColumns: ProColumns<TrainList, 'chargeStatus' | 'involvedType' | 'involvedStatus' | 'signStatus'>[] = [{
    title: '授权ID',
    dataIndex: 'customerCode',
    hideInSearch: true,
    width: 100,
  }, {
    title: '姓名',
    dataIndex: 'name',
    hideInSearch: true,
    width: 130,
  }, {
    title: '经销商等级',
    dataIndex: 'customerLevels.equals',
    width: 80,
    hideInSearch: true,
    render: (dom: any, record: any) => {
      return <ModalForm
        title="经销商等级信息"
        width={600}
        trigger={
          <Tooltip title="查看经销商等级信息">
            <Button size="small" onClick={() => setRandomText(new Date().getTime())} type="link">查看</Button>
          </Tooltip>
        }
        form={form}
        preserve={false}
        onFinish={async () => true}
      >
        {record.customerLevels && record.customerLevels.map((e: any) => <p>{e.levelName}---{e.belongCustomerName}({e.belongCustomerCode})</p>)}
      </ModalForm>;
    }
  }, {
    title: '性别',
    dataIndex: 'sex',
    hideInSearch: true,
    width: 120,
    valueEnum: sex,
  }, {
    title: '报名时间',
    dataIndex: 'involveTime',
    width: 140,
    hideInSearch: true,
  }, {
    title: '报名人数',
    dataIndex: 'involveNumber',
    width: 150,
    hideInSearch: true,
  }, {
    title: '报名费用',
    dataIndex: 'involveAmount',
    width: 150,
    hideInSearch: true,
  }, {
    title: '培训费余额',
    width: 100,
    dataIndex: 'trainAmount',
    hideInSearch: true,
  }, {
    title: '贷款余额',
    width: 150,
    dataIndex: 'goodsAmount',
    hideInSearch: true,
  }, {
    title: '会议期间下单金额',
    width: 200,
    dataIndex: 'duringOrderAmount',
    hideInSearch: true,
  }, {
    title: '会议期间充值金额',
    width: 180,
    dataIndex: 'rechargeOrderAmount',
    hideInSearch: true,
  }, {
    title: '近半年下单金额',
    width: 180,
    dataIndex: 'halfYearOrderAmount',
    hideInSearch: true,
  }, {
    title: '报名类型',
    dataIndex: 'involvedType',
    width: 180,
    valueType: 'involvedType',
    hideInSearch: true,
  }, {
    title: '签到时间',
    dataIndex: 'signTime',
    width: 160,
    hideInSearch: true,
  }, {
    title: '参与状态',
    dataIndex: 'status',
    width: 160,
    valueType: 'involvedStatus',
    hideInSearch: true,
  }, {
    title: '签到状态',
    dataIndex: 'signStatus',
    width: 160,
    valueType: 'signStatus',
    hideInSearch: true,
  }, {
    title: '房间号',
    dataIndex: 'roomNo',
    width: 130,
    hideInSearch: true,
  }, {
    title: '扣款状态',
    dataIndex: 'chargeStatus',
    width: 180,
    valueType: 'chargeStatus',
    hideInSearch: true,
  }, {
    title: '是否扣款成功',
    dataIndex: 'isChargeSuccess',
    hideInSearch: true,
    valueEnum: LiveGoods,
    width: 180,
  }, {
    title: '已扣费金额',
    dataIndex: 'chargeAmount',
    hideInSearch: true,
    width: 160,
  }, {
    title: '备注',
    dataIndex: 'remark',
    hideInSearch: true,
    width: 80,
  }, {
    title: '操作',
    dataIndex: '',
    hideInSearch: true,
    width: 200,
    render: (dom, record) => {
      return <>

        <ModalForm
          title="编辑参会人信息"
          width={800}
          trigger={
            <Tooltip title="编辑">
              <Button size="small" onClick={() => setRandomText(new Date().getTime())} type="link">编辑</Button>
            </Tooltip>
          }
          form={form}
          preserve={false}
          onFinish={async (data: any) => {
            data.checkRooms = data.checkRooms.concat().map((e: any, index: number) => {
              delete e.involveDetail;
              const newItem = {
                ...e,
                isCarry: (index === 0) ? true : false,
              };
              return newItem;
            });
            return new Promise<boolean>((resolve, reject) => {
              editParticipation(data).then(e => {
                message.success('编辑成功');
                setRefreshTag(new Date().getTime());
                resolve(true);
              }).catch(error => {
                message.error(error?.response?.data?.title);
                resolve(false);
              })
            });
          }}
        >
          <ParticipationControl key={randomText} form={form} meetId={entity.id} record={record} />
        </ModalForm>
        <ChangeRoomnumberPop
          trigger="修改房间号"
          checkRooms={record.checkRooms}
          onFinish={(data) => {
            return new Promise<any>((resolve, reject) => {
              console.log(data)
              console.log('获取过来的制')
              editeRoomNos(data).then((e) => {
                message.success('编辑成功')
                setRefreshTag(new Date().getTime());
                resolve(true)
              }).catch((e) => {
                
                console.log(e.response.data.message)
                message.error(e.response.data.message)
                resolve(false)
              })

            })

          }}></ChangeRoomnumberPop>
        <Popconfirm
          title="你确定要删除这条数据吗？"
          onConfirm={() => {
            deleteParticipation(record.id).then(e => {
              message.success('删除成功');
            }).catch(error => {
              message.error(error?.response?.data?.title);
            });
            setRefreshTag(new Date().getTime());
          }}
        >
          <Button
            danger
            type="link"
            size="small"
            color="#ee2222"
          >删除</Button>
        </Popconfirm>
      </>
    }
  }, {
    title: '经销商授权码',
    dataIndex: 'customerCode.equals',
    hideInTable: true,
    valueType: 'select',
    renderFormItem: (text, record, valueType) => {
      return <ProFormText
        name="customerCode.equals"
        placeholder="经销商授权码"
        label="经销商授权码"
      />
    },
  }, {
    title: '房间号',
    dataIndex: 'roomNo.equals',
    hideInTable: true,
    valueType: 'select',
    renderFormItem: (text, record, valueType) => {
      return <ProFormText
        name="roomNo.equals"
        placeholder="房间号"
        label="房间号"
      />
    },
  }, {
    title: '参与状态',
    dataIndex: 'involvedStatus.equals',
    valueType: 'select',
    hideInTable: true,
    renderFormItem: (text, record, valueType) => {
      return <ProFormSelect
        name="involvedStatus.equals"
        options={convertEnumToOptionType('involvedStatus')}
        placeholder="参与状态"
        label="参与状态"
      />
    },
  }, {
    title: '签到状态',
    dataIndex: 'signStatus.equals',
    valueType: 'select',
    hideInTable: true,
    renderFormItem: (text, record, valueType) => {
      return <ProFormSelect
        name="signStatus.equals"
        options={convertEnumToOptionType('signStatus')}
        placeholder="签到状态"
        label="签到状态"
      />
    },
  }, {
    title: '扣款状态',
    dataIndex: 'chargeStatus.equals',
    hideInTable: true,
    valueType: 'select',
    renderFormItem: (text, record, valueType) => {
      return <ProFormSelect
        name="chargeStatus.equals"
        label="扣款状态"
        options={convertEnumToOptionType('chargeStatus')}
        placeholder="扣款状态"
      />
    },
  }, {
    title: '报名开始时间',
    dataIndex: 'involveTime.greaterThanOrEqual',
    hideInTable: true,
    valueType: 'select',
    renderFormItem: (text, record, valueType) => {
      return <ProFormDateTimePicker
        name="involveTime.greaterThanOrEqual"
        label="报名开始时间"
        placeholder="报名开始时间"
      />
    },
  }, {
    title: '报名结束时间',
    dataIndex: 'involveTime.lessThanOrEqual',
    hideInTable: true,
    valueType: 'select',
    renderFormItem: (text, record, valueType) => {
      return <ProFormDateTimePicker
        name="involveTime.lessThanOrEqual"
        label="报名结束时间"
        placeholder="报名结束时间"
      />
    },
  }, {
    title: '随员信息',
    dataIndex: 'accompanying',
    hideInSearch: true,
    hideInTable: true,
  }, {
    title: '美人计等级信息',
    dataIndex: 'MRJCustomerLevels',
    hideInSearch: true,
    hideInTable: true,
  }, {
    title: '黑天鹅等级信息',
    dataIndex: 'HTECustomerLevels',
    hideInSearch: true,
    hideInTable: true,
  }, {
    title: '中康等级信息',
    dataIndex: 'ZKCustomerLevels',
    hideInSearch: true,
    hideInTable: true,
  }, {
    title: '朱莉安娜女王等级信息',
    dataIndex: 'ZLACustomerLevels',
    hideInSearch: true,
    hideInTable: true,
  },]


  const tableAlertOptionRender = (res: any) => {
    const ids = res.selectedRowKeys;
    return <ModalForm
      title="会议扣费"
      width={400}
      trigger={
        <Tooltip title="会议扣费">
          <Button type="primary">会议扣费</Button>
        </Tooltip>
      }
      preserve={false}
      onVisibleChange={(v) => !v && res.onCleanSelected()}
      form={form}
      onFinish={async (data: any) => {
        return new Promise<boolean>((resolve, reject) => {
          meetingDeduction({ ...data, ids }).then(e => {
            res.onCleanSelected();
            message.success('操作成功');
            setRefreshTag(refreshTag + 1);
            resolve(true);
          }).catch(error => {
            message.error(error?.response?.data?.title);
            resolve(false);
          })
        });
      }}
    >
      <ProFormSelect
        name="chargeStatus"
        options={[{
          label: "全部扣款", value: 'All_Deduction',
        }, {
          label: "部分扣款", value: 'Part_Deduction',
        }]}
        placeholder="扣款状态"
        label="扣款状态"
      />
      <ProFormDependency name={['chargeStatus']}>
        {({ chargeStatus }) => {
          return chargeStatus === 'Part_Deduction' && <>
            <ProFormDigit
              name="amount"
              label="扣款金额"
              fieldProps={{
                precision: 2
              }}
            />
          </>
        }
        }
      </ProFormDependency>
    </ModalForm>
  }

  //下载全部回调
  const exportData = async () => {
    let page = 0;
    let size = 100;
    let allData: any[] = [];
    let total = 0;

    do {
      const params: any = {
        'meetId.equals': entity?.id,
        sort: 'lastModifiedDate,desc',
        page,
        size
      };

      const fetchText = (record: any, storeId: any) => {
        let CustomerLevels: any = '';
        if (!!record.customerLevels) {
          const obj = record.customerLevels.filter((e: any) => e.storeId === storeId)?.[0];
          const objText = obj ? (obj.levelName + '---' + obj.belongCustomerName + '(' + obj.belongCustomerCode + ')') : '';
          CustomerLevels = record.customerLevels ? objText : '';
        };

        return CustomerLevels;
      };

      const fetchTextAccompanyingText = (record: any) => {
        const newArr = record.checkRooms ? record.checkRooms.filter((e: any) => !e.isCarry) : [];
        let accompanying: any = '';
        if (!!newArr) {
          newArr.forEach((item: any) => {
            const sex = item.sex === 1 ? '男' : '女';
            accompanying += (item.name + '--' + sex + '--' + item.phone + '--' + item.roomNo + '；')
          });
        };
        return accompanying;
      }

      const getData: any = await fetchTrainListDetailsList(params);
      const newData = getData.data.concat().map((item: any) => {
        return ({
          ...item,
          MRJCustomerLevels: fetchText(item, 1),
          HTECustomerLevels: fetchText(item, 2),
          ZKCustomerLevels: fetchText(item, 4),
          ZLACustomerLevels: fetchText(item, 3),
          accompanying: fetchTextAccompanyingText(item),
        })
      });
      allData = allData.concat(newData);
      total = getData.headers['x-total-count'] || 0;
      ++page;
    } while ((page - 1) * size < total);
    return allData
  }

  const toolbarActions: ReactNode[] = [
    <Button type="primary" onClick={() => {
      statisticsTrain(entity.id).then(e => {
        message.success('统计成功');
        setRefreshTag(new Date().getTime());
      }).catch(error => {
        message.error(error?.response?.data?.title);
      })
    }}>统计</Button>,
    <ModalForm
      title="添加参会人员"
      width={1000}
      trigger={
        <Tooltip title="添加参会人员">
          <Button type="primary" onClick={() => setRandomText(new Date().getTime())}>添加参会人员</Button>
        </Tooltip>
      }
      preserve={false}
      form={form}
      onFinish={async (data: any) => {
        return new Promise<boolean>((resolve, reject) => {
          data.sex = data.checkRooms[0].sex;
          data.roomNo = data.checkRooms[0].roomNo;
          data.checkRooms = data.checkRooms.concat().filter((e: any, index: number) => index !== 0).map((e: any) => ({ ...e, isCarry: false }));
          addParticipation(data).then(e => {
            message.success('添加成功');
            setRefreshTag(new Date().getTime());
            resolve(true);
          }).catch(error => {
            message.error(error?.response?.data?.title);
            resolve(false);
          })
        });
      }}
    >
      <ParticipationControl key={randomText} meetId={entity.id} form={form} />
    </ModalForm>,
    <ModalForm
      title="房间使用情况"
      width={800}
      trigger={
        <Tooltip title="房间使用情况">
          <Button type="primary">房间使用情况</Button>
        </Tooltip>
      }
      onValuesChange={() => setRefreshTag(new Date().getTime())}
      preserve={false}
      form={form}
      onFinish={async (data: any) => {
        return true;
      }}
    >
      <ProTable<any>
        columns={roomColumns as any}
        rowKey="id"
        key={refreshTag}
        params={{ refreshTag }}
        request={async (params) => {
          const newFilterObj: any = {};
          const objArr = Object.keys(params);
          objArr.forEach((key, index) => {
            const val = params[key];
            if (val !== '' && key !== 'searchText') {
              newFilterObj[key] = val;
            };
          });
          let res = await fetchRoomsList({
            ...newFilterObj,
            sort: 'lastModifiedDate,desc',
            page: (params.current && params.current - 1) || 0,
            size: params.pageSize || 20,
          }, entity.id);
          return Promise.resolve({
            data: res?.data || [],
            success: true,
            total: res.headers['x-total-count'] || 0
          });
        }}
        options={false}
      />
    </ModalForm>,
    <ModalForm
      title="会议二维码"
      width={'350px'}
      trigger={
        <Tooltip title="会议二维码">
          <Button type="primary">会议二维码</Button>
        </Tooltip>
      }
      preserve={false}
      form={form}
      onFinish={async (data: any) => {
        return true;
      }}
    >
      <QRCodeControl QRCodeValue={JSON.stringify({ id: entity.id + '', type: 'meet' })} downloadFileName="会议二维码" />
    </ModalForm>,
    <ExportDataDialog
      columns={detailedColumns.concat().filter((e: any) => e.dataIndex && (e.dataIndex.split('.').length === 1))}
      exportData={exportData}
      hiddenDownloadCurrent={true}
    />]

  return (
    <DetailsPage<TrainList, 'trainType'>
      title={'培训会详情'}
      hideEdit={true}
      {...props}
    >
      <Image style={{ height: '100px' }} src={entity.coverPicUrl} />
      <ProDescriptions<TrainList>
        columns={props.columns as any}
        dataSource={props.entity}
        column={4}
      >
      </ProDescriptions>
      <Title style={{ margin: '20px 0 0 0' }} level={5}>参会人员列表</Title>
      {entity?.id && <ProTable<any>
        columns={detailedColumns as any}
        rowKey="id"
        params={{ 'meetId.equals': entity?.id, refresh: refreshTag }}
        search={{
          filterType: 'light',
        }}
        className="trainList-details-bodList"
        toolbar={{
          actions: toolbarActions,
        }}
        scroll={{ x: 1400 }}
        request={async (params) => {
          const newFilterObj: any = {};
          const objArr = Object.keys(params);
          objArr.forEach((key, index) => {
            const val = params[key];
            if (val !== '' && key !== 'searchText') {
              newFilterObj[key] = val;
            };
          });
          let res = await fetchTrainListDetailsList({
            ...newFilterObj,
            sort: 'lastModifiedDate,desc',
            page: (params.current && params.current - 1) || 0,
            size: params.pageSize || 20,
          });
          return Promise.resolve({
            data: entity?.id ? (res?.data || []) : [],
            success: true,
            total: entity?.id ? (res.headers['x-total-count'] || 0) : 0
          });
        }}
        rowSelection={{
          type: 'checkbox',
          getCheckboxProps: (record: any) => ({
            disabled: ['All_Deduction', 'Part_Deduction'].indexOf(record.chargeStatus) !== -1, // Column configuration not to be checked
          }),
        }}
        tableAlertOptionRender={tableAlertOptionRender}
        options={false}
      />}

    </DetailsPage>);
};

const mapStateToProps = ({ trainList }: RootState) => ({
  entity: trainList.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TrainListDetails);
